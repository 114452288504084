.view-charging-station-warp {
    padding: 20px;
    width: 50vw;

    .facility-details {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .form-field {
            flex-basis: 48%;
        }

        .form-amenities-icons {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            padding: 10px;

            .title-label {
                font-size: 16px;
                font-weight: 500;
                color: var(--grey-1);
            }
            .icon-list {
                display: flex;
                flex-wrap: wrap;
                gap: 30px;

                .icon-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    .icon {
                        padding: 10px;
                        border-radius: 12px;
                        background-color: var(--border-grey-1);
                    }
                    .title {
                        color: var(--grey-1);
                        font-size: 12px;
                    }
                }
            }
        }
        .form-bottom-button.table-bottom-button-list {
            width: 100%;
            justify-content: end;
        }
    }
}