.revenue-body {
  width: 100%;
  height: calc(100vh - 100px);

  display: grid;
  grid-template-rows: 45px 55px calc(100vh - 200px);

  .revenue-toggle-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .auto-complete-select-wrap {
      width: 350px;
    }
  }

  .table-content {
    width: 100%;
    height: 100%;
  }
}

// Styles applied for table
.table-row-input-field {
  border: none;
  outline: none;

  border-bottom: 1px solid var(--text-color);
}
