.tab-menus-warp {
  // background: var(--tab-bg);
  border-radius: 12px;
  border: solid 1px var(--grey-4);
  padding: 5px;

  ul {
    list-style: none;
    display: flex;
    position: relative;
    align-items: center;
    .tab {
      margin: 0;
      padding: 0;
      flex: 1;
      text-align: center;
      border-radius: 8px;
      z-index: 2;
      transition: all .5s ease-in-out;
      cursor: pointer;
  
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 7px 40px;
        position: relative;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 14px;

        .tag {
          padding: 8px;
          position: absolute;
          right: 25px;
          top: 0;
          background-color: var(--tag-error);
          font-size: 11px;
        }
      }

      &.active {
        color: var(--white-color);
      }
    }
    .bg {
      background-color: var(--primary-color);
      width: calc(100% / var(--menu-count, 0));
      position: absolute;
      border-radius: 8px;
      z-index: 1;
      left: 50%;
      top: 0;
      height: 100%;
      transition: all .5s ease-in-out;
    }
    @for $i from 0 through 10 {
      #button-#{$i}.active ~ .bg {
        left: calc((100% / var(--menu-count, 0)) * #{$i});
      }
    }
  }
}

@media screen and (max-width: 1537px) {
  .tab-menus-warp {
    ul {
      .tab {
        div {
          font-size: 13px;

          .tag {
            font-size: 8.5px;
            width: 18px;
            height: 18px;
            right: 20px;
          }
        }
      }
    }
  }
}