.button-wrap {
  position: relative;
  width: 100%;
  height: max-content;
  display: flex;
  button {
    width: 100%;
    height: 32px; // For most of the button 38px is fixed
    padding: 0 16px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 0.8px;

    border: none;
    background-color: transparent;
    cursor: pointer;

    .icon {
      display: flex;
    }

    // &:disabled {
    //   color: var(--white-color);
    //   border: 1px solid var(--grey-2);
    //   background-color: var(--grey-2);
    // }
  }

  .button-loader-wrap {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    padding: 3px;

    .button-loader-container {
      width: 100%;
      height: 90%;
      border-radius: 8px;

      position: relative;
      .button-loader {
        height: 100%;
        width: 10px;
        border-radius: 5px;

        position: absolute;
        animation: buttonLoaderAnimation 2s infinite;
      }
    }
  }

  button span:first-child {
    display: flex;
  }

  .submit-button {
    color: var(--white-color);
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);

    &:hover {
      color: var(--white-color);
      border: 1px solid var(--primary-color-hover);
      background-color: var(--primary-color-hover);

    } 
    
  }

  .back-button {
    transition: all 0.2s ease-in;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);

    &:hover {
      color: var(--white-color);
      border: 1px solid var(--primary-color);
      background-color: var(--primary-color);
    }
  }

  .danger-outline-button {
    transition: all 0.2s ease-in;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);

    &:hover {
      color: var(--white-color);
      border: 1px solid var(--error);
      background-color: var(--error);
    }
  }

  .error-button {
    color: var(--white-color);
    border: 1px solid var(--error);
    background-color: var(--error);
  }

  .primary-outline-button {
    font-weight: 500;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 0 6px;
  }
}


@keyframes buttonLoaderAnimation {
  0% {
    top: 0%;
    left: 0%;
    transform: rotate(0deg);
    background: #ffffff;
    box-shadow: #ffffff 0 0 25px 10px;
    backdrop-filter: blur(12px);
  }

  25% {
    background: #ededed99;
    box-shadow: 0px 0px 1.47px 0px #f9f9f999;
    box-shadow: 0px 0px 2.95px 0px #f9f9f999;
    box-shadow: 0px 0px 10.32px 0px #f9f9f999;
    box-shadow: 0px 0px 20.64px 0px #f9f9f999;
    box-shadow: 0px 0px 35.39px 0px #f9f9f999;
    box-shadow: 0px 0px 61.93px 0px #f9f9f999;
    backdrop-filter: blur(12px);
  }

  50% {
    top: 0%;
    left: calc(100% - 16px);
    transform: rotate(180deg);
    background: #ffffff;
    box-shadow: #ffffff 0 0 25px 10px;
    backdrop-filter: blur(12px);
  }

  75% {
    background: #ededed99;
    box-shadow: 0px 0px 1.47px 0px #f9f9f999;
    box-shadow: 0px 0px 2.95px 0px #f9f9f999;
    box-shadow: 0px 0px 10.32px 0px #f9f9f999;
    box-shadow: 0px 0px 20.64px 0px #f9f9f999;
    box-shadow: 0px 0px 35.39px 0px #f9f9f999;
    box-shadow: 0px 0px 61.93px 0px #f9f9f999;
    backdrop-filter: blur(12px);
  }

  100% {
    top: 0%;
    left: 0%;
    transform: rotate(0deg);
    background: #ffffff;
    box-shadow: #ffffff 0 0 25px 10px;
    backdrop-filter: blur(12px);
  }
}

@media screen and (max-width: 1537px) {
  .button-wrap {
    button {
      height: auto;
      padding: 6px;
      font-size: 14px;
    }
    .primary-outline-button {
      padding: 6px;
    }
    .back-button {
      padding: 6px !important;
    }
  }
}