.success-notification-wrap {
  height: 300px;
  width: 450px;

  .success-notification-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .success-icon {
    }

    .success-content {
      text-align: center;
      h1 {
        font-size: 20px;
        font-weight: 600;
        color: var(--success);
      }

      p {
        margin: 20px 0 0;
        font-size: 16px;
        font-weight: 400;
        color: var(--grey-6);
      }
    }
  }
}
