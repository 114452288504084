.custom-input-date-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-label {
    font-size: 16px;
    font-weight: 500;
    color: var(--grey-1);

    span{
      color: var(--error);
    }
  }
  .custom-date-input {
    display: flex;
    flex-direction: row;
    gap: 20px;

    position: relative;

    .custom-input-container {
      max-width: 100%;
      height: 48px; // Customize if need
      overflow: hidden;
      flex: 1;

      padding: 0 14px;
      display: flex;
      align-items: center;
      border-radius: 12px;
      border: 1px solid var(--border-grey);
      background-color: var(--grey-bg);
  
      .value-view {
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent;
  
        font-size: 14px;
        font-weight: 300;

        p {
          color: var(--grey-2);
        }
      }
      span {
        cursor: pointer;
      }
    }
  }
}

.calender-box {
  position:  absolute;
  top: 48px;
  z-index: 1300;

  display: flex;
  flex-direction: column;
  gap: 10px;

  
  border-radius: 12px;
  border: 1px solid var(--border-grey);
  // background: var(--grey-bg);
  background-color: var(--grey-select-bg);
  --Webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  height: 265px;
  width: 282px;
  padding: 10px;

  .calender-header {
    width: 100%;
    // height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .calender-header-left {
      cursor: pointer;
      transform: rotate(180deg);
      display: flex;
    }

    .calender-header-center {
      display: flex;
      justify-content: center;
      flex: 1;
    }

    .calender-header-right {
      cursor: pointer;
      display: flex;
    }

  }
  .calender-body {
    flex: 1;
    display: flex;
    flex-direction: column;

    .calender-header-items {
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto;
      gap: 2px;


      width: 100%;

      .header-item {
        flex: 1;
        display: flex;
        justify-content: center;
        font-size: 13px;
        padding-bottom: 10px;
        border-bottom: solid 1px #ccc;
      }
    }
    .calender-body-items {
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto;
      gap: 2px;


      width: 100%;

      .header-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;

        width: 39px;
        height: 40px;
        cursor: pointer;
        border-radius: 8px;
        box-sizing: border-box;

        &:hover {
          background-color: var(--grey-3);
          border: solid 0.1px var(--grey-1);
        }
      }
    }
  }
}