.custom-select-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  .input-label {
    font-size: 16px;
    font-weight: 500;
    color: var(--grey-1);

    span{
      color: var(--error);
    }
  }

  .custom-select-container {
    position: relative;
    max-width: 100%;
    height: 48px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid var(--border-grey);
    background-color: var(--grey-select-bg);
    

    display: flex;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;

    flex-wrap: wrap;

    &.error {
      border: 1px solid var(--error);
    }

    &.open {
      border-radius: 8px 8px 0 0;
    }

    select {
      width: 100%;
      min-width: 50px;
      height: 100%;
      padding: 0 14px;
      border-radius: 12px;

      font-size: 14px;
      font-weight: 300;
      appearance: none;
      background-color: transparent;
      border: none;
      outline: none;

      cursor: pointer;

      optgroup {

        option {
          background-color: rgba(var(--grey-bg), 0.5);
        }
      }
    }

    .custom-icon {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }
  .dropdown-items-list {
    display: none;
    max-height: 300px;
    box-sizing: border-box;
    position: absolute;
    padding: 20px 8px;
    border: 1px solid var(--border-grey);
    border-top: 1px solid var(--grey-1);
    background-color: var(--grey-select-bg);
    border-radius: 0px 0px 8px 8px;
    backdrop-filter: blur(12px);
    --Webkit-backdrop-filter: blur(12px);
    
    // display: flex;
    flex-direction: column;
    z-index: 1;

    &.open {
      display: flex;
    }

    .items {
      cursor: pointer;
      padding: 6px 3px;
      color: var(--grey-2);
      padding: 10px;
      border-radius: 8px;

      &:hover {
        color: var(--black-color);
        background-color: var(--pure-white-color);
      }
    }

  }
  p {
    font-size: 14px;
  }
}
