.custom-file-input-wrap {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
  }
  .custom-file-input-container {
    // width: 100%;
    min-height: 48px; // Customize if need
    overflow: hidden;

    padding: 0 14px;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--border-grey);
    background-color: rgba(var(--grey-bg), 0.5);

    .selected-file-wrap {
      width: 100%;
      display: flex;
      align-items: center;

      .selected-files-view-wrap {
        display: flex;
        align-items: center;

        .single-selected-wrap {
          width: 28px;
          height: 28px;

          display: flex;
          align-items: center;
          justify-content: center;
          border: 1.5px solid var(--normal-white);
          background-color: var(--grey-5);

          border-radius: 4px;

          .selected-image {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 4px;
          }

          .selected-video {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 4px;
          }

          .number-of-files {
            font-size: 12px;
            font-weight: 600;
            color: var(--text-color);
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            margin-left: -14px;
          }
        }
      }

      .placeholder {
        font-size: 14px;
        font-weight: 300;
        color: var(--grey-2);
      }
    }

    .file-input-icon-wrap {
      display: flex;
      .file-input-icon {
        display: flex;
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
