.add-account-form {
    width: 500px;

    &.loader {
        min-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form-body {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .end {
            justify-content: end !important;
        }
    }
}

.filter-user-warp {
    padding: 20px;
    width: 50vw;

    .form-body {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 20px;

        .form-bottom-button {
            grid-column: 2 span;
            justify-content: end !important;
        }
    }
}

@media only screen and (max-width: 800px) {
    .filter-user-warp {
        .form-body {
            display: flex;
            flex-direction: column;
        }
    }
}
