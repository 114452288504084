.page-title-wrap {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .page-title-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 10px 2px;

    h1 {
      font-size: 16px;
      font-weight: 600;

      color: var(--text-color-1);
    }

  }

}

.page-title-wrap-toggle {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .page-title-left {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h1 {
      font-size: 16px;
      font-weight: 600;

      color: var(--text-color-1);
    }

  }

}