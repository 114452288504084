.container {
    padding: 0px 20px;
    .header-title {
        padding: 10px 0px;
        width: 100%;
    }
    .body-container {
        display: flex;
        gap: 20px;
        flex-direction: column;
        .user-filter-form {
            display: grid;
            padding: 10px 0px;
            grid-template-columns: auto auto auto;
            gap: 30px;
            .form-field {
                min-width: 300px;
                width: 80%;
            }
            .down {
                display: flex;
                align-items: end;
            }
        }
        .card-table-tab {
            display: flex;
            justify-content: space-between;

            .tab-menus-warp {
                ul li div {
                    padding: 10px 30px;
                }
            }
        }
        .table-content {
            .card-details {
                padding: 0px 0px 0px 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .table-top-button-list {
                    justify-content: end;

                    .table-button-item {
                        .button-wrap {
                            .back-button {
                                padding: 0 6px;
                                border: 1px solid var(--grey-4);
                            }
                        }
                    }
                    
                }
                .card-list {
                    // display: flex;
                    // flex-direction: row;
                    // flex-wrap: wrap;
                    // align-items: flex-start;

                    display: grid;
                    grid-template-columns: repeat(4, 1fr);

                    height: calc(100vh - 378px);
                    overflow-y: scroll;
                    gap: 20px;

                    &.consumer-list {
                        height: calc(100vh - 305px);
                    }
                    .table-no-data-wrap {
                        grid-column-start: span 3;
                    }
                    
                    .fixed-grid {
                        grid-column-start: span 4;

                        position: relative;
                        width: 100%;
                        height: 295px;
                        animation: scrollDown 2s;
                    }

                    .card-box-warp {
                        .card-body {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            padding: 20px;

                            .card-header {
                                display: flex;
                                gap: 20px;

                                .card-header-item-image {
                                    width: 60px;
                                    border-radius: 50%;
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                    }
                                }
                                .card-header-item-title {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 5px;
                                    flex: 1;

                                    .title {
                                        font-size: 16px;
                                        font-weight: 550;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        width: 100%;
                                        letter-spacing: 0.7px;
                                    }
                                    .sub-title {
                                        letter-spacing: 0.6px;
                                    }
                                }
                                .card-header-item-tag {
                                    .tag-success-label {
                                        padding: 3px 10px;
                                    }
                                }
                            }
                            .card-body-content {
                                display: grid;
                                grid-template-columns: auto auto;
                                gap: 15px;

                                .user-details {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 5px;

                                    .title {
                                        font-size: 15px;
                                        font-weight: 400;
                                        letter-spacing: 0.7px;
                                    }
                                    .value {
                                        display: flex;
                                        align-items: center;
                                        gap: 10px;
                                        font-size: 16px;
                                        font-weight: 550;
                                        letter-spacing: 0.7px;
                                        .btn {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                            .card-body-footer button {
                                width: 100%;
                            }
                        }
                    }
                }
                .pagination {
                    padding: 5px 10px;
                    display: flex;
                    justify-content: space-between;

                    .table-bottom-button-list {
                        display: flex;
                        gap: 30px;
                        .table-button-item {
                            .user-count {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                            
                                .icon {
                                  display: flex;
                                }
                            
                                .value {
                                    font-size: 19px;
                                    font-weight: 500;
                                    letter-spacing: 0.6px
                                }
                              }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1570px) {
    .container .body-container .table-content .card-details .card-list {
        grid-template-columns: repeat(3, 1fr);
        .fixed-grid {
            grid-column-start: span 3;
        }
    }
}
@media screen and (max-width: 1333px) {
    .container .body-container .table-content .card-details .card-list {
        grid-template-columns: repeat(2, 1fr);
        .fixed-grid {
            grid-column-start: span 2;
        }
    }
}
@media screen and (max-width: 840px) {
    .container .body-container .table-content .card-details .card-list {
        grid-template-columns: 100%;
        .fixed-grid {
            grid-column-start: span 1;
        }
    }
}

@media screen and (max-width: 1120px) {
    .container {
        .body-container {
            .user-filter-form {
                grid-template-columns: auto auto;
            }
        }
    }
}
@media screen and (max-width: 790px) {
    .container {
        .body-container {
            .user-filter-form {
                grid-template-columns: auto;
            }
        }
    }
}

@keyframes scrollDown {
    0% {
        opacity: 0; /* Start position */
        top: -300px;
        height: 0px;
    }
    100% {
        opacity: 1; /* Back to start */
        top: 0;
        height: 295px;
    }
}

  
@media screen and (max-width: 1537px) {
    .container {
        .body-container {
            .card-table-tab {
                .tab-menus-warp {
                    ul li div {
                        padding: 5px 20px;
                    }
                }
            }
            .table-content {
                .card-details {
                    .card-list {
                        height: calc(100vh - 320px);
                        &.consumer-list {
                            height: calc(100vh - 270px);
                        }
                        .card-box-warp {
                            .card-body {
                                .card-header {
                                    .card-header-item-image {
                                        width: 40px;
                                    }
                                    .card-header-item-title {
                                        .title {
                                            font-size: 14px;
                                        }
                                        .sub-title {
                                            font-size: 13px;
                                        }
                                    }
                                    .card-header-item-tag {
                                        .tag-success-label {
                                            padding: 2px 7px;
                                            font-size: 12px;
                                        }
                                    }
                                }
                                .card-body-content {
                                    .user-details {
                                        .title {
                                            font-size: 12px;
                                        }
                                        .value {
                                            font-size: 13px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .pagination {
                        .table-bottom-button-list {
                            .table-button-item {
                                .user-count {
                                    .value {
                                        font-size: 14px;
                                    }
                                  }
                            }
                        }
                    }
                }
            }
        }
    }
}