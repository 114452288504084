.login-wrap {
  width: 100%;
  height: 100%;
  display: flex;

  background: url(../../../assets/images/login/background.jpg);
  // background-repeat: no-repeat;
  background-size: cover;
  object-fit: fill;

  .videoTag {
    position: fixed;
    width: 100vw;
    height: 100vh;
    object-fit: fill;
  }

  .login-banner-container {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .login-banner {
      width: 75%;
      height: 75%;
      object-fit: contain;
    }
  }

  .login-form-container {
    flex: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    .login-form-with-border {
      width: 70%;
      height: max-content;

      position: relative;
      padding: 1.5px;

      .login-form {
        position: relative;
        z-index: 2;
        padding: 40px 30px;
        border-radius: 24px;

        display: flex;
        flex-direction: column;
        gap: 25px;

        // background: rgb(from var(--grey-bg) r g b / 0.8);
        

        h1 {
          margin: 0 0 10px;
          font-size: 24px;
          font-weight: 700;

          background: -webkit-linear-gradient(
            var(--primary-color), var(--gradient-green)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .password-container {
          .forgot-password {
            margin: 10px 0 0;
            text-align: end;
            span {
              font-size: 14px;
              font-weight: 400;
              text-decoration: underline;

              cursor: pointer;
              color: var(--primary-color);
            }
          }
        }
      }
      .forget-password-form {
        position: relative;
        z-index: 2;
        padding: 40px 30px;
        border-radius: 24px;

        display: flex;
        flex-direction: column;
        gap: 25px;

        // background: rgb(from var(--grey-bg) r g b / 0.8);
        

        h1 {
          font-size: 18px;
          font-weight: 700;

          color: var(--text-color);
        }

        .resend-otp-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          .resend-timer-wrap {
            display: flex;
            align-items: center;
            gap: 6px;
            color:var(--text-color);
  
            .resend-icon {
              display: flex;
            }
  
            span {
              font-size: 14px;
              font-weight: 500;
            }
          }
  
          .resendOtp {
            font-size: 14px;
            font-weight: 500;
            text-decoration: underline;
  
            cursor: pointer;
  
            color: var(--grey-1);
  
            &.disabled {
              cursor: not-allowed;
            }
          }
        }
      }

      .gradient-border {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;
        padding: 1.5px;
        border-radius: 24px;

        background: linear-gradient(to bottom var(--primary-color), var(--gradient-green));

        .white-bg-color {
          width: 100%;
          height: 100%;
          background-color: #ffffff; // Reason for using color code: Form background color should have glassy effect
          border-radius: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .login-wrap {
    .login-banner-container {
      flex: 100%;
      svg {
        width: 300px;
      }
    }

    .login-form-container {
      flex: 100%;
      .login-form-with-border {
        width: 100%;
        .login-form {
          padding: 30px 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .login-wrap {
    .login-banner-container {
      display: none;
    }

    .login-form-container {
      flex: 100%;
      .login-form-with-border {
        width: 100%;
        .login-form {
          padding: 30px 20px;
        }
      }
    }
  }
}