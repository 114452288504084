.custom-table-wrap {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: max-content 1fr max-content;
  overflow-x: scroll;

  .custom-table {
    width: 100%;
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar-corner {
      display: none;
    }

    .table-container {
      position: relative;
      width: 100%;
      height: 100%;

      display: grid;
      grid-template-rows: max-content 1fr;
      gap: 10px;

      .table-header-wrap {
        position: sticky;
        top: 0;
        z-index: 2;

        height: 40px;
        border-radius: 8px;

        border: 1px solid var(--grey-4);
        background-color: var(--white-color);

        .table-header-container {
          height: 100%;
          display: flex;

          &.no-action {
            padding: 0px 25px 0px 0px;
          }

          .table-header {
            height: 100%;
            padding: 0 0 0 15px;

            display: flex;
            align-items: center;

            text-align: start;

            border-right: 2px solid var(--grey-4);
            color: var(--table-header);

            color: var(--grey-2);

            &:last-child {
              border: none;
            }

            p {
              color: var(--table-header);
              font-weight: 500;
              font-size: 12px;

              width: 100%;
              white-space: nowrap; /* Prevents line breaks */
              overflow: hidden; /* Hides overflow content */
              text-overflow: ellipsis;
              letter-spacing: 0.6px;
            }
            &.serial {
              min-width: 75px !important;
            }

            div {
              margin-right: 10px;
              margin-top: 5px;
              color: var(--grey-6);
              cursor: pointer;
            }

            .resize-button {
              margin-right: 1px;
              cursor: col-resize;
            }
          }
        }
      }

      .table-body {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .table-loading {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .table-row {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          padding: 0px 25px;

          display: flex;

          background-color: rgba(var(--grey-bg), 0.6);
          border: 1px solid var(--grey-4);

          box-shadow: 2px 2px 6px 0px #00000014;

          .table-row-data {
            padding: 0 0 0 15px;

            font-size: 11px;
            font-weight: 500;
            // flex: 1;

            display: flex;
            align-items: center;

            color: var(--table-data);
            border-right: 2px solid var(--grey-4);

            &:last-child {
              border: none;
            }

            &.serial {
              min-width: 50px !important;
            }

            &.action {
              min-width: 55px;
            }

            .table-row-each-data {
              font-size: 11px;
              font-weight: 500;

              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.align-data-to-right {
                text-align: right;
                padding: 0 15px 0 0;
              }
            }

            .table-button {
              padding: 5px;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              color: var(--primary-color);
              background: var(--grey-3);
            }

            &.center {
              justify-content: center;
            }

            .action-button {
              cursor: pointer;
              margin: 2px;
              padding: 3px;
              border-radius: 8px;
              background-color: var(--border-grey-1);
            }

            .action-button-group {
              display: flex;
              gap: 5px;
              width: 100%;
              justify-content: center;
              align-items: center;

              .action-button-item {
                display: flex;
                cursor: pointer;
                padding: 3px;
                border-radius: 6px;
                background-color: var(--border-grey-1);

                &.delete-color {
                  color: var(--error);
                }
                &.view-color {
                  color: var(--primary-color);
                }
              }
            }
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &.small {
        gap: 10px;

        .table-header-wrap {
          height: 40px;

          .table-header-container {
            .table-header {
              p {
                font-weight: 500;
                font-size: 12px;
              }
            }
          }
        }

        .table-body {
          gap: 5px;

          .table-row {
            width: 100%;
            height: 40px;

            .table-row-data {
              font-size: 11px;
              font-weight: 500;

              .table-row-each-data {
                font-size: 11px;
                font-weight: 500;
              }
            }
          }
        }
      }

      &.medium {
        gap: 10px;

        .table-header-wrap {
          height: 45px;

          .table-header-container {
            .table-header {
              p {
                font-weight: 500;
                font-size: 15px;
              }
            }
          }
        }

        .table-body {
          gap: 5px;

          .table-row {
            width: 100%;
            height: 45px;

            .table-row-data {
              font-size: 13px;
              font-weight: 500;

              .table-row-each-data {
                font-size: 13px;
                font-weight: 500;
              }
            }
          }
        }
      }

      &.large {
        gap: 10px;

        .table-header-wrap {
          height: 50px;

          .table-header-container {
            .table-header {
              p {
                font-weight: 500;
                font-size: 16px;
              }
            }
          }
        }

        .table-body {
          gap: 5px;

          .table-row {
            width: 100%;
            height: 50px;

            .table-row-data {
              font-size: 15px;
              font-weight: 500;

              .table-row-each-data {
                font-size: 15px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .user-count {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
      display: flex;
    }

    .value {
      font-size: 19px;
      font-weight: 500;
      letter-spacing: 0.6px;
    }
  }

  .custom-table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    padding: 10px 0 5px;
  }
}

.document-in-station-table {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  .document-container {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    gap: 5px;
  }

  .document-count-wrap {
    width: 18px;
    height: 18px;
    background-color: var(--grey-3);
    border-radius: 50%;

    display: flex;
    align-items: center;
    text-align: center;

    .table-row-each-data {
      font-weight: 600 !important;
    }
  }
}

.table-alert-tag {
  width: 100%;
  .table-alert-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    span {
      font-weight: 7000;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.medias-in-station-table {
  .selected-files-view-wrap {
    display: flex;
    align-items: center;

    .single-selected-wrap {
      width: 28px;
      height: 28px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1.5px solid var(--normal-white);
      background-color: var(--grey-3);

      border-radius: 4px;

      .selected-image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        border-radius: 4px;
      }

      .selected-video {
        width: 100%;
        height: 100%;
        object-fit: cover;

        border-radius: 4px;
      }

      .number-of-files {
        font-size: 12px;
        font-weight: 600;

        color: var(--text-color);
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-left: -14px;
      }
    }
  }
}

.table-no-data-wrap {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .no-data-found-image {
    width: 250px;
    height: auto;
    object-fit: contain;
  }

  .no-data-found-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: var(--primary-color);
    }

    p {
      width: 70%;
      margin: 5px 0 0;
      font-size: 16px;
      font-weight: 700;
      color: var(--grey-1);
    }
  }
}

@media screen and (max-width: 1537px) {
  .custom-table-wrap {
    .user-count {
      .value {
        font-size: 14px;
      }
    }
    .custom-table {
      .table-container {
        &.small {
          .table-header-wrap {
            height: 40px;
            .table-header-container {
              .table-header {
                p {
                  font-size: 11px;
                }
              }
            }
          }
          .table-body {
            .table-row {
              min-height: 40px;
              .table-row-data {
                div {
                  font-size: 12px;
                }
                .table-row-each-data {
                  font-size: 11px;
                }
              }
            }
          }
        }
        &.medium {
          .table-header-wrap {
            height: 45px;
            .table-header-container {
              .table-header {
                p {
                  font-size: 13px;
                }
              }
            }
          }
          .table-body {
            .table-row {
              min-height: 45px;
              .table-row-data {
                div {
                  font-size: 13px;
                }
                .table-row-each-data {
                  font-size: 13px;
                }
                .tag-default-label {
                  padding: 2px 6px;
                }
              }
            }
          }
        }
        &.large {
          .table-header-wrap {
            height: 50px;
            .table-header-container {
              .table-header {
                p {
                  font-size: 15px;
                }
              }
            }
          }
          .table-body {
            .table-row {
              min-height: 50px;
              .table-row-data {
                div {
                  font-size: 15px;
                }
                .table-row-each-data {
                  font-size: 15px;
                }
                .tag-default-label {
                  padding: 2px 6px;
                }
              }
            }
          }
        }
      }
    }
  }
  .medias-in-station-table {
    .selected-files-view-wrap {
      .single-selected-wrap {
        width: 27px;
        height: 27px;
      }
    }
  }
}
