.drop-down {
    position: relative;
    .drop-down-title {
        display: flex;
    }
    .drop-down-content {
        display: none;
        position: absolute;
        animation: animatetop .4s ease-in-out;
        background: var(--modal-box-body-bg);
        backdrop-filter: blur(4px);
        border-radius: 12px;
        right: 0;
        padding: 10px;
        z-index: 99;
        &.open {
            display: flex;
        }
        flex-direction: column;

        .drop-down-content-list-item {
            cursor: pointer;
        }
    }
}
.drop-down-content {
    display: flex;
    position: absolute;
    max-width: 100px;
    // animation: animateTopBottom .5s ease-in-out;
    background: var(--modal-box-body-bg);
    backdrop-filter: blur(4px);
    border-radius: 12px;
    right: 0;
    padding: 10px;
    z-index: 99;
    flex-direction: column;

    .drop-down-content-list-item {
        cursor: pointer;
        padding: 5px;

        &:hover {
            color: var(--primary-color);
        }
        // border-bottom: solid 1px var(--black-color);
    }
}
@keyframes animateTopBottom {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}