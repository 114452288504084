.modal-box-wrap {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in-out;
    background-color: var(--modal-box-bg);

    &.right-top {
        justify-content: end;
        align-items: start;
        .modal-box-container {
            display: flex;
            flex-direction: column;
            animation: modalAnimateRight .4s ease-out;
            border-radius: 0px 0px 0px 16px;
        }
    }
    &.filter-modal {
        display: block;
        .modal-box-container {
            top: 22%;
            margin-left: 150px;
            width: 60vw;
            display: flex;
            position: relative;
            flex-direction: column;
            animation: modalAnimateRight .4s ease-out;
        }
    }

    .modal-box-container {
        min-width: 300px;
        // min-height: 300px;
        position: relative;
        padding: 0;
        border-radius: 16px;
        // background-color: #e4e9eb;
        opacity: 2;
        // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        animation: modalAnimateTop .4s ease-out;
        border-color: solid 1px var(--border-grey);
        background-color: var(--modal-box-body-bg);
        backdrop-filter: blur(12px);
        --Webkit-backdrop-filter: blur(12px);
        .modal-box-header {
            padding: 20px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            background-color: var(--modal-box-header-bg);
            .modal-box-title {
                flex: 1;
                text-align: center;

                span {
                    color: #111727;
                    font-weight: bold;
                }
            }
            .modal-box-icon {
                cursor: pointer;
                position: absolute;
                right: 20px;
            }
        }
        .modal-box-body {
            .modal-box-icon {
                position: absolute;
                right: 20px;
            }
            padding: 20px 30px;

            .conform-box-body {
                padding: 50px 0px 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 30vw;
                .title {
                    font-size: 22px;
                    font-weight: 600;
                }
                .sub-title {
                    font-size: 18px;
                    text-align: center;
                    color: var(--grey-1)
                }
            }
            .form-bottom-button {
                justify-content: center;
                .table-button-item {
                    width: 115px;
                }
            }
        }
    }
}

@keyframes modalAnimateTop {
    from {
        bottom: -600px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

@keyframes modalAnimateRight {
    from {
        right: -600px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}