* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  font-family: 'Nunito', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}