.gallery-view-wrap {
  width: 600px;

  .gallery-view-container {
    width: 100%;
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    .gallery-view-column {
      width: 100%;
      height: max-content;

      display: flex;
      flex-direction: column;
      gap: 10px;

      .single-selected-wrap {
        position: relative;
        width: 100%;
        height: max-content;

        border-radius: 4px;
        border: 1.5px solid var(--normal-white);

        .selected-image {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 4px;
        }

        .selected-video {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 4px;
        }

        .remove-icon {
          position: absolute;
          top: 7px;
          right: 7px;

          color: var(--error);
          cursor: pointer;
        }
      }
    }
  }
  .upload-more-files-wrap {
    margin: 25px 20px 0 0;
    display: flex;
    justify-content: flex-end;

    color: var(--primary-color);

    .upload-more-files {
      display: flex;
      align-items: center;
      gap: 10px;

      cursor: pointer;
    }
  }
}
