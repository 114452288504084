.admin-header-warp {
    padding: 20px;
    width: 100%;
    height: 100%;
    .admin-header-content {
        height: 100%;
        padding: 10px;
        display: flex;
        border-width: 1px;
        border-style: solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to left, #F8F9FA, #A0A7AF 50%, #F8F9FA);
        border-radius: 100px;
        align-items: center;
        // position: fixed;
        width: calc(100vw - 165px);
        z-index: 99;
        // background: var(--header-bg);
        .admin-header-left {
            height: 100%;
            display: none;
            align-items: center;
            padding-right: 20px;
        }
        .admin-header-center {
            height: 100%;
            flex: 1;
            text-align: center;

            .header-image {
                height: 100%;
                img {
                    height: 100%;
                    width: auto;
                    object-fit: contain;
                  }
            }
        }
        .admin-header-right {
            display: flex;
            gap: 40px;
            .item {
                position: relative;
                display: flex;
                align-items: center;

                .notification {
                    cursor: pointer;

                    .tag {
                        position: absolute;
                        top: 0px;
                        right: -7px;
                    }
                }

                .dropdown-notification {
                    position: absolute;
                    top: 60px;
                    right: 0;
                    z-index: 10;
                    .dropdown-layout {
                        position: relative;
                        border-radius: 12px;
                        width: 160px;
                        padding: 15px;
                        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
                        animation: animatetop .4s ease-out;
                        background: var(--dropdown-bg);
                    }
                }

                .profile {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    .profile-image {
                        width: 50px;
                        height: 50px;
                        border-radius: 50px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .profile-details {
                        display: flex;
                        gap: 10px;
                        cursor: pointer;

                        .profile-name {
                            font-weight: 600;
                            font-size: 15px;
                        }
                    }
                    .dropdown-content {
                        position: absolute;
                        top: 60px;
                        z-index: 10;
                        .dropdown-layout {
                            position: relative;
                            border-radius: 12px;
                            width: 160px;
                            padding: 15px;
                            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
                            animation: animatetop .4s ease-out;
                            background: var(--dropdown-bg);

                            display: flex;
                            flex-direction: column;
                            gap: 20px;

                            .dropdown-header {
                                display: flex;
                                justify-content: space-between;

                                .header-item {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 37px;
                                    height: 37px;
                                    border-radius: 10px;
                                    cursor: pointer;

                                    &:hover {
                                        background: var(--selected-bg);
                                    }
                                    &.open {
                                        background: var(--selected-bg);
                                    }
                                }
                            }
                            .dropdown-body {
                                // padding: 20px 0 0 0;
                                .body-list {
                                    display: flex;
                                    flex-direction: column;

                                    .item {
                                        padding: 5px 3px;
                                        display: flex;
                                        justify-content: space-between;
                                        cursor: pointer;

                                        .list-icon {
                                            rotate: 270deg;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes animatetop {
    from {
        top: 200px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@media screen and (max-width: 1537px) {
    .admin-header-warp {
        padding: 15px;
        .admin-header-content {
            padding: 5px;
            .admin-header-right {
                .item {
                    .profile {
                        .profile-image {
                            width: 35px;
                            height: 35px;
                        }
                        .profile-details {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}