.container {
    padding: 10px 20px;
    .header-title {
        padding: 10px 0px;

        h2 {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.5px;
        }
    }
    .body-container {
        display: flex;
        gap: 20px;
        flex-direction: column;
        .filter-form {
            display: flex;
            flex-wrap: wrap;
            padding: 10px 0px;
            gap: 30px;
            .form-field {
                min-width: 300px;
                flex: 1;
                .custom-input-wrap, .custom-select-wrap {
                    width: 70%;
                }
            }
        }
        hr {
            border: none;
            height: 1px;
            width: 100%;
            margin: 0px;
            
            background-color: var(--border-grey);
        }
    }
}

@media screen and (max-width: 1537px) {
    .container {
        .header-title {
            h2 {
                font-size: 15px;
            }
        }
    }
}