.end-consumer-warp {
    .table-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .reports-graph-content {
            border-radius: 16px;
            background-color: var(--pure-white-color);
            
            .reports-graph-header-container {
                .reports-title {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 20px 20px 0 20px;
                }
                
                .reports-graph-header {
                    justify-content: space-between;
                    display: flex;
                    padding: 20px;
                    align-items: center;
                    
                    .reports-toggle-btn {
                        .toggle-button {
                            padding: 0px;
                            border: none;
                            background-color: var(--white-color);
                            
                            
                            .btn {
                                padding: 14px 28px;
                                margin-right: 0px;
                                border: 0.5px solid var(--grey-4);
                                border-radius: 0px;
                                
                                &.active {
                                    background-color: var(--secondary-color);
                                    color: var(--white-color);
                                    border: 0.1px solid var(--secondary-color);
                                    
                                }
                                
                                &:first-child {
                                    border-radius: 10px 0 0 10px;
                                    margin-right: 0;
                                }
                                
                                &:last-child {
                                    margin-right: 0;
                                    border-radius: 0px 10px 10px 0px;
                                    
                                }
                                
                            }
                        }
                    }
                }
            }
            
            .reports-graph {
                padding: 20px;
                max-width: 100%;
            }
        }
    }
}