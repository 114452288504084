.card-box-warp {
    height: max-content;
    padding: 2px;
    border-radius: 12px;
    background: var(--card-border-gradient-color);

    .card-content {
        // padding: 20px;
        border-radius: 11px;
        width: 100%;
        height: 100%;
        background: var(--white-color);
    }

    &:hover {
        padding: 4px;

        .card-content {
            border-radius: 9px;
            background: var(--card-bg-hover-gradient-color);

            .card-body {
                padding: 18px !important;
            }
        }
    }
}