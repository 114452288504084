.dynamic-pricing-wrap {
  width: 100%;
  height: calc(100vh - 100px);

  display: grid;
  grid-template-rows: 45px 55px calc(100vh - 200px);

  .dynamic-pricing-toggle {
    width: 420px;
  }

  .table-container {
    width: 100%;
    height: 100%;

    .dynamic-pricing-table {
      width: inherit;
      height: inherit;
    }
  }
}

// Styles applied for table
.dynamic-pricing-custom-label {
  display: flex;
  align-items: center;
  gap: 5px;
}
