// ------------------------------ Theme Definition -------------------------------

$themes: (
  light: (
    primary-color: $primary-color--light,
    primary-color-hover: $primary-color-hover--light,
    secondary-color: $secondary-color--light,
    success: $success--light,
    error: $error--light,
    tag-error: $tag-error--light,
    white-color: $white-color--light,
    pure-white-color: $pure-white-color--light,
    black-color: $black-color--light,
    success-bg: $success-bg--light,
    error-bg: $error-bg--light,
    warning: $warning--light,
    warning-bg: $warning-bg--light,

    // Color used for gradient
    gradient-green: $gradient-green--light,
    gradient-blue: $gradient-blue--light,

    // Text color
    text-color: $text-color--light,
    grey-1: $grey-1--light,
    grey-2: $grey-2--light,
    grey-3: $grey-3--light,
    grey-4: $grey-4--light,

    // Colors-user-in-table
    table-header: $table-header--light,
    table-data: $table-data--light,
    table-border: $table-border--light,
    row-bg: $row-bg--light,

    // Background-color
    success-button-bg: $success-button-bg--light,
    grey-bg: $grey-bg--light,
    grey-select-bg: $grey-select-bg--light,
    header-bg: $header-bg--light,
    tab-bg: $tab-bg--light,

    // Border-color
    border-grey: $border-grey--light,
    border-grey-1: $border-grey-1--light,

    // Modal-box
    modal-box-body-bg: $modal-box-body-bg--light,
    modal-box-header-bg: $modal-box-header-bg--light,
    modal-box-bg: $modal-box-bg--light,

    //Dropdown
    dropdown-bg: $dropdown-bg--light,
    selected-bg: $selected-bg--light,

    // Border gradient color
    border-gradient-color: $border-gradient-color--light,
    card-border-gradient-color: $card-border-gradient-color--light,
    card-bg-gradient-color: $card-bg-gradient-color--light,
    card-bg-hover-gradient-color: $card-bg-hover-gradient-color--light,
    background-gradient-color: $background-gradient-color--light,
  ),
  dark: (
    primary-color: $primary-color--dark,
    primary-color-hover: $primary-color-hover--dark,
    secondary-color: $secondary-color--dark,
    success: $success--dark,
    error: $error--dark,
    tag-error: $tag-error--dark,
    white-color: $white-color--dark,
    pure-white-color: $pure-white-color--dark,
    black-color: $black-color--dark,
    success-bg: $success-bg--dark,
    error-bg: $error-bg--dark,
    warning: $warning--dark,
    warning-bg: $warning-bg--dark,

    // Color used for gradient
    gradient-green: $gradient-green--dark,
    gradient-blue: $gradient-blue--dark,

    // Text color
    text-color: $text-color--dark,
    grey-1: $grey-1--dark,
    grey-2: $grey-2--dark,
    grey-3: $grey-3--dark,
    grey-4: $grey-4--dark,

    // Colors-user-in-table
    table-header: $table-header--dark,
    table-data: $table-data--dark,
    table-border: $table-border--dark,
    row-bg: $row-bg--dark,

    // Background color
    success-button-bg: $success-button-bg--dark,
    grey-bg: $grey-bg--dark,
    grey-select-bg: $grey-select-bg--dark,
    header-bg: $header-bg--dark,
    tab-bg: $tab-bg--dark,

    // Border-color
    border-grey: $border-grey--dark,
    border-grey-1: $border-grey-1--dark,

    // Modal-box
    modal-box-body-bg: $modal-box-body-bg--dark,
    modal-box-header-bg: $modal-box-header-bg--dark,
    modal-box-bg: $modal-box-bg--dark,
    
    //Dropdown
    dropdown-bg: $dropdown-bg--dark,
    selected-bg: $selected-bg--dark,
    
    // Border gradient color
    border-gradient-color: $border-gradient-color--dark,
    card-border-gradient-color: $card-border-gradient-color--dark,
    card-bg-gradient-color: $card-bg-gradient-color--dark,
    card-bg-hover-gradient-color: $card-bg-hover-gradient-color--dark,
    background-gradient-color: $background-gradient-color--dark,
  ),
  voltie: (
    primary-color: $primary-color--voltie,
    primary-color-hover: $primary-color-hover--voltie,
    secondary-color: $secondary-color--voltie,
    success: $success--voltie,
    error: $error--voltie,
    tag-error: $tag-error--voltie,
    white-color: $white-color--voltie,
    pure-white-color: $pure-white-color--voltie,
    black-color: $black-color--voltie,
    success-bg: $success-bg--voltie,
    error-bg: $error-bg--voltie,
    warning: $warning--voltie,
    warning-bg: $warning-bg--voltie,

    // Color used for gradient
    gradient-green: $gradient-green--voltie,
    gradient-blue: $gradient-blue--voltie,

    // Text color
    text-color: $text-color--voltie,
    grey-1: $grey-1--voltie,
    grey-2: $grey-2--voltie,
    grey-3: $grey-3--voltie,
    grey-4: $grey-4--voltie,

    // Colors-user-in-table
    table-header: $table-header--voltie,
    table-data: $table-data--voltie,
    table-border: $table-border--voltie,
    row-bg: $row-bg--voltie,

    // Background-color
    success-button-bg: $success-button-bg--voltie,
    grey-bg: $grey-bg--voltie,
    grey-select-bg: $grey-select-bg--voltie,
    header-bg: $header-bg--voltie,
    tab-bg: $tab-bg--voltie,

    // Border-color
    border-grey: $border-grey--voltie,
    border-grey-1: $border-grey-1--voltie,

    // Modal-box
    modal-box-body-bg: $modal-box-body-bg--voltie,
    modal-box-header-bg: $modal-box-header-bg--voltie,
    modal-box-bg: $modal-box-bg--voltie,

    //Dropdown
    dropdown-bg: $dropdown-bg--voltie,
    selected-bg: $selected-bg--voltie,

    // Border gradient color
    border-gradient-color: $border-gradient-color--voltie,
    card-border-gradient-color: $card-border-gradient-color--voltie,
    card-bg-gradient-color: $card-bg-gradient-color--voltie,
    card-bg-hover-gradient-color: $card-bg-hover-gradient-color--voltie,
    background-gradient-color: $background-gradient-color--voltie,
  ),
  voltie-dark: (
    primary-color: $primary-color--voltie-dark,
    primary-color-hover: $primary-color-hover--voltie-dark,
    secondary-color: $secondary-color--voltie-dark,
    success: $success--voltie-dark,
    error: $error--voltie-dark,
    tag-error: $tag-error--voltie-dark,
    white-color: $white-color--voltie-dark,
    pure-white-color: $pure-white-color--voltie-dark,
    black-color: $black-color--voltie-dark,
    success-bg: $success-bg--voltie-dark,
    error-bg: $error-bg--voltie-dark,
    warning: $warning--voltie-dark,
    warning-bg: $warning-bg--voltie-dark,

    // Color used for gradient
    gradient-green: $gradient-green--voltie-dark,
    gradient-blue: $gradient-blue--voltie-dark,

    // Text color
    text-color: $text-color--voltie-dark,
    grey-1: $grey-1--voltie-dark,
    grey-2: $grey-2--voltie-dark,
    grey-3: $grey-3--voltie-dark,
    grey-4: $grey-4--voltie-dark,

    // Colors-user-in-table
    table-header: $table-header--voltie-dark,
    table-data: $table-data--voltie-dark,
    table-border: $table-border--voltie-dark,
    row-bg: $row-bg--voltie-dark,

    // Background color
    success-button-bg: $success-button-bg--voltie-dark,
    grey-bg: $grey-bg--voltie-dark,
    grey-select-bg: $grey-select-bg--voltie-dark,
    header-bg: $header-bg--voltie-dark,
    tab-bg: $tab-bg--voltie-dark,

    // Border-color
    border-grey: $border-grey--voltie-dark,
    border-grey-1: $border-grey-1--voltie-dark,

    // Modal-box
    modal-box-body-bg: $modal-box-body-bg--voltie-dark,
    modal-box-header-bg: $modal-box-header-bg--voltie-dark,
    modal-box-bg: $modal-box-bg--voltie-dark,
    
    //Dropdown
    dropdown-bg: $dropdown-bg--voltie-dark,
    selected-bg: $selected-bg--voltie-dark,
    
    // Border gradient color
    border-gradient-color: $border-gradient-color--voltie-dark,
    card-border-gradient-color: $card-border-gradient-color--voltie-dark,
    card-bg-gradient-color: $card-bg-gradient-color--voltie-dark,
    card-bg-hover-gradient-color: $card-bg-hover-gradient-color--voltie-dark,
    background-gradient-color: $background-gradient-color--voltie-dark,
  ),
);


// Function to set the CSS - variable globally

// Loop through theme map and define CSS variables
@mixin generate-theme-variables($theme) {
  @each $key, $value in map-get($themes, $theme) {
    --#{$key}: #{$value};
  }
}

// Create global CSS variables for each theme
:root {
  @include generate-theme-variables(light); // Default theme is light
}

[data-theme='light'][data-branding='voltie'] {
  @include generate-theme-variables(voltie); // voltie-Dark theme variables
}

[data-theme='dark'][data-branding='energic'] {
  @include generate-theme-variables(dark); // Dark theme variables
}

[data-theme='dark'][data-branding='voltie'] {
  @include generate-theme-variables(voltie-dark); // Dark theme variables
}
