.export-button-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  .back-button {
    width: 100%;
    padding: 4px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    letter-spacing: 0.8px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);

    &:hover {
      color: var(--white-color);
      border: 1px solid var(--primary-color);
      background-color: var(--primary-color);
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.export-buttons-container {
  width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    transition: all .5s ease-in-out;
    background-color: var(--modal-box-bg);
  .export-buttons-list {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 16px;
    .export-button-item {
      cursor: pointer;
    }
    .progress-bar {
      border-radius: 10px;
      overflow: hidden;
      width: 150px;
  
      .bar {
        background: white;
  
        .progress {
          animation: loader 3s ease infinite;
          // Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating.
          background: var(--primary-color);
          color: var(--white-color);
          padding: 5px;
          width: 0;
        }
      }
    
      span {
        display: block;
      }
    
    }
  }

}


@keyframes loader {

	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}

}
