.toast-message-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;

  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .toast-message-container {
    position: absolute;
    left: 50%;
    bottom: 3%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    gap: 10px;

    padding: 12px;
    border-radius: 15px;

    background-color: var(--grey-9);
    box-shadow: 2px 2px 6px 0px #0000003b;
    backdrop-filter: blur(12px);

    animation: moveBottomToTop 0.4s linear;

    &.error {
      span {
        display: flex;

        &:last-child {
          cursor: pointer;
        }
      }

      p {
        font-size: 14px;
        font-weight: 600;
      }
    }

    &.success {
    }
  }
}

@keyframes moveBottomToTop {
  0% {
    opacity: 0;
    bottom: -3%;
  }

  100% {
    opacity: 1;
    bottom: 3%;
  }
}
