.custom-radio-button-wrap {
  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;

  .radio-button-status {
    position: relative;
    width: 24px;
    height: 24px;

    border-radius: 50%;
    border: 2px solid var(--primary-color);

    &.active {
      background-color: var(--primary-color);
    }

    .radio-button-selected {
      width: 14px;
      height: 14px;
      border-radius: 50%;

      background-color: var(--white-color);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .radio-button-label {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
  }
}
