.payment-management-wrap {
  width: 100%;
  height: calc(100vh - 100px);

  display: grid;
  grid-template-rows: 45px calc(100vh - 145px);

  .table-content {
    width: 100%;
    height: 100%;
  }
}