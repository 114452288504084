.sidebar-wrap {
  position: relative;
  width: 100%; // 120px as width was given in .ev-layout-wrap
  height: 100%;

  .sidebar-container {
    position: absolute;
    left: 15px;
    top: 0;
    z-index: 3;

    height: 100%;
    border-radius: 150px 150px 50px 50px;

    transition: all 0.4s ease-in-out;

    &.expand {
      width: 400px;
      z-index: 401;
      border-radius: 50px 50px 25px 25px;
    }

    &.close {
      width: 105px; // 120px - 15px -> 105px
    }

    background-color: rgb(from var(--grey-bg) r g b / 0.6);
    backdrop-filter: blur(4px);

    .toggle-button {
      margin: 14px 0 0 20px;
      position: relative;
      width: 60.87px;
      height: 60.87px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 3.23px 6.46px 19.39px 0px #00000026;

      &.expand {
        background: linear-gradient(to bottom, var(--primary-color), var(--gradient-green));
      }
      cursor: pointer;
      background-color: var(--grey-4);

      .toggle-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px; /* Set the width of the icon */
        height: 24px; /* Set the height of the icon */
        margin-top: -12px; /* Offset by half of the icon height */
        margin-left: -12px; /* Offset by half of the icon width */

        display: flex;
        transition: all 0.4s ease-in-out;

        &.active {
          opacity: 1;
          transform: rotate(0deg);
        }

        &.disable {
          opacity: 0;
          pointer-events: none;
          transform: rotate(180deg);
        }

        &.open-active {
          opacity: 1;
          scale: 1;
        }

        &.open-disable {
          opacity: 0;
          scale: 8;
          pointer-events: none;
        }

        &:last-child {
          color: var(--black-color);
        }
      }
    }

    .sidebar-menus {
      padding: 15px 25px;

      display: flex;
      flex-direction: column;
      gap: 20px;

      height: calc(100vh - 80px);
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        display: none;
      }

      .sidebar-menu {
        // display: inline-block;
        width: 54px;
        height: 54px;
        border-radius: 15px;
        transition: all 0.4s ease-in-out;

        cursor: pointer;

        &.active {
          border: 1px solid transparent;
          background:
            linear-gradient(
                to bottom,
                rgb(from var(--primary-color) r g b / 0.2),
                rgb(from var(--gradient-green) r g b / 0.2)
              )
              padding-box,
            linear-gradient(
                261.23deg,
                rgb(from var(--primary-color) r g b / 0.1),
                rgb(from var(--gradient-blue) r g b / 0.1)
              )
              border-box;
          box-shadow: 0px 3.5px 5.5px 0px #00000005;
        }

        &.in-active {
          border: 1px solid transparent;
          background:
            linear-gradient(to bottom, transparent, transparent) padding-box,
            linear-gradient(261.23deg, transparent, transparent) border-box;
        }

        &.expand {
          width: 350px;
        }

        &.close {
          width: 54px;
        }

        .menu-wrap {
          margin: 5px;
          position: relative;
          display: grid;
          grid-template-columns: 42px 1fr;
          gap: 8px;
          align-items: center;
          background: transparent;

          .menu-icon-container {
            position: relative;
            z-index: 2;

            width: 100%;
            height: 42px;
            border-radius: 12px;
            transition: all 0.4s ease-in-out;

            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 12px;
              background: linear-gradient(to bottom, var(--primary-color), var(--gradient-green));
              opacity: 0;
              transition: opacity 0.4s ease-in-out;
            }

            &.active::before {
              opacity: 1;
            }

            &.inactive::before {
              opacity: 0;
            }

            span {
              position: relative;
              z-index: 2;

              display: flex;
            }
          }

          .menu-label {
            white-space: nowrap; // Prevent text from breaking into multiple lines
            transition: all 0.2s ease-in-out;
            letter-spacing: 0.6px;

            &.active {
              font-size: 18px;
              font-weight: 600;

              background: -webkit-linear-gradient(var(--primary-color), var(--gradient-green));
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            &.in-active {
              font-size: 16px;
              font-weight: 400;
              color: var(--secondary-color);
            }

            &.expand {
              opacity: 1;
              transform: translateX(0); // Incoming animation (on toggle open)
            }

            &.close {
              opacity: 0;
              transform: translateX(60px); // Outgoing animation (on toggle close)
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1537px) {
  .sidebar-wrap {
    .sidebar-container {
      .toggle-button {
        .toggle-icon.active {
        }
      }
    }
  }
}
