.download-qr-with-functionality-wrap {
  height: calc(100vh - 100px);
  overflow: hidden;

  .download-qr-wrap {
    .download-qr-container {
      height: calc(100vh - 100px);
      overflow: scroll;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .download-qr-input-wrap {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .download-qr-button-wrap {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .download-qr-preview-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    width: 100%;
    height: calc(100vh - 150px);
    overflow: scroll;
  }

  .download-qr-preview-wrap,
  .download-qr-functionality-wrap {
    .download-qr-single-container {
      width: max-content;

      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      align-items: center;

      padding: 40px 35px;
      background: linear-gradient(to bottom, var(--primary-color), var(--gradient-green));
      border-radius: 10px;

      .qr-top-details {
      }

      .qr-container {
        display: flex;
        border: 6px solid #000; // As color is same for both light and dark modes
      }

      .qr-bottom-details {
      }
    }

    .button-wrap {
      width: max-content;

      button {
        padding: 10px 70px;
      }
    }
  }

  .download-qr-functionality-wrap {
    width: max-content;
    height: max-content;
  }
}
