.station-container {
    .body-container {
        gap: 15px;
        .table-tab {
            display: flex;
            justify-content: start;
            .tab-menus-warp {
                ul {
                    $button-count: 2;
                }
            }
        }
        .table-content {
            .tab-body {
                overflow: hidden;
                width: 100%;
                .tab-contents {
                    display: flex;
                    width: 200%;
                    transform: translateX(-50%);
                    transition: all .5s ease-in-out;
    
                    &.active {
                        transform: translateX(0);
                    }
                }
            }
            .custom-table-wrap {
                .custom-table {
                    .table-container {
                        .table-body {
                            .table-row {
                                .table-row-data {
                                    .tag-default-label {
                                        max-width: 80%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}