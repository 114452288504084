.custom-input-time-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-label {
    font-size: 16px;
    font-weight: 500;
    color: var(--grey-1);

    span{
      color: var(--error);
    }
  }
  .custom-time-input {
    display: flex;
    flex-direction: row;
    gap: 20px;

    position: relative;

    .custom-input-container {
      max-width: 100%;
      height: 48px; // Customize if need
      overflow: hidden;
      flex: 1;

      padding: 0 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      border: 1px solid var(--border-grey);
      background-color: var(--grey-bg);

      input {
        width: 100%;
        // height: 100%;
        border: none;
        outline: none;
        background-color: transparent;

        font-size: 14px;
        font-weight: 300;
        padding: 0px 0px 0px 3px;
        width: 20px;
        text-align: center;

        &::placeholder {
          color: var(--grey-2);
        }
        &[type='number']::-webkit-outer-spin-button,
        &[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type='number'] {
          -moz-appearance: textfield; // Hide arrows in Firefox
        }
      }
  
      .value-view {
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent;
  
        font-size: 14px;
        font-weight: 300;

        p {
          color: var(--grey-2);
        }
      }
      span {
        cursor: pointer;
      }
    }
  }
  .text-danger {
    font-size: 12px;
  }
}

.timer-box {
  position:  absolute;
  top: 48px;
  z-index: 1300;

  display: flex;
  flex-direction: column;
  gap: 10px;

  
  border-radius: 12px;
  border: 1px solid var(--border-grey);
  // background: var(--grey-bg);
  background-color: var(--grey-select-bg);
  --Webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 188px;
  width: 282px;
  padding: 10px;

  .time-picker {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .time-picker-body {
      display: flex;
      gap: 10px;
      align-items: center;
      .time-picker-change-button {
        flex: 1;
        border: solid 1px var(--grey-4);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 8px;
        background-color: var(--grey-4);
        display: flex;
        flex-direction: column;
        gap: 5px;
        text-align: center;
        overflow: hidden;
        .top-button{
          flex: 1;
          display: flex;
          justify-content: center;
          cursor: pointer;
          padding: 10px 0px;
          span{
            transform: rotate(270deg);
            display: flex;
          }

          &:hover {
            background-color: var(--grey-2);
          }
        }
        .text {
          padding: 10px 0px;
          color: var(--text-color);
        }
        .bottom-button {
          flex: 1;
          display: flex;
          justify-content: center;
          cursor: pointer;
          padding: 10px 0px;
          span{
            transform: rotate(90deg);
            display: flex;
          }

          &:hover {
            background-color: var(--grey-2);
          }
        }
      }
    }
    .time-picker-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  
}