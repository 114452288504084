.qr-code-field-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .qr-code-field-first-row {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .qr-code-serial-number-input {
      // padding: 0 15px 0 0;
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      align-items: center;
      gap: 10px;

      // border-right: 1px solid var(--text-color);

      .select-either-or {
        font-size: 14px;
        font-weight: 400;
        color: var(--grey-2);
      }

      .xlFile-selection-wrap {
        display: grid;
        grid-template-rows: max-content 12px;
        gap: 5px;

        .warning {
          margin: 0 0 0 5px;
          font-size: 12px;
          color: var(--warning);
        }
      }
    }

    .qr-code-logo-input {
      // padding: 0 0 0 15px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      gap: 10px;
    }
  }

  .qr-code-text-area-wrap {
  }
}
