.add-account-form {
    width: 500px;
    max-height: 70vh;
    overflow-y: scroll;
    scrollbar-width: none;
    .form-body {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form-bottom-button {
            justify-content: end;
        }
    }

    .view-body {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .profile-details {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            .profile-image {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }
            .profile-user {
                text-align: center;
                .profile-title-1 {
                    font-size: 16px;
                    font-weight: 600;
                }
                .profile-title-2 {
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }

        .tab-body {
            overflow: hidden;
            width: 100%;
            .tab-contents {

                .tab-item {
                    flex: 1;

                    .item-content {
                        padding: 10px;
                        .content-body {
                            border-image: var(--border-gradient-color);
                            background-image: var(--background-gradient-color);
                            border-width: 4px;
                            border-style: solid;
                            border-radius: 12px;
                            padding: 20px;
                            .list-item {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                padding-top: 10px;

                                .total-details {
                                    display: flex;
                                    gap: 5px;
                                    flex-direction: column;
                                    .total {
                                        font-size: 18px;
                                        font-weight: 600;

                                        &.center {
                                            text-align: center;
                                        }
                                    }
                                    .active {
                                        color: var(--success);
                                        display: flex;
                                        gap: 10px;
                                        align-items: center;
                                    }
                                }
                            }
                        }
                        .step-3 {
                            display: flex;
                            flex-direction: column;
                            gap: 30px;
                            .middle-price {
                                display: flex;
                                justify-content: center;
                                .price-amount {
                                    font-size: 22px;
                                    font-weight: 600;
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                }
                            }
                            .footer-detail {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                font-size: 19px;
                            }
                        }

                        .documents-session {
                            margin-top: 20px;
                            .title {
                                color: var(--grey-1);
                                font-size: 19px;
                                font-weight: 500;
                            }
                            .document-list {
                                padding-top: 10px;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;

                                .document-item {
                                    display: flex;
                                    flex-direction: row;
                                    gap: 10px;
                                    padding: 6px 10px;
                                    border-radius: 8px;
                                    align-items: center;
                                    background: #ECF5F880;
                                    background: var(--tab-bg);

                                    .name {
                                        flex: 1;
                                        width: 100%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-bottom-button {
            justify-content: end;
        }
    }
}