.multi-select-dropdown {
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
        font-size: 14px;
        margin-bottom: 4px;
        color: #333;
        display: block;

        span{
          color: var(--error);
        }
    }

    .multi-select-dropdown-list {
        position: relative;


        .dropdown-header {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 12px;
            border: 1px solid #E3E8F1;
            border-radius: 12px;
            background-color: #ECF5F880;
            cursor: pointer;

            .left-section {
                display: flex;
                align-items: center;
                gap: 6px;
                height: 24px;

                span {
                    font-size: 16px;
                    color: #333;
                }
            }

            .dropdown-arrow {
                font-size: 18px;
                transition: transform 0.3s ease;

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        .dropdown-content {
            top: 87%;
            width: 100%;
            position: absolute;
            z-index: 999;
            border: 1px solid #e3e8f1;
            border-radius: 0 0 8px 8px;
            background-color: #ecf5f880;
            max-height: 0;
            opacity: 0;
            transform: translateY(-80%);
            overflow-y: hidden;
            display: flex;
            flex-direction: column;
            gap: 20px;
            transition: transform 1s ease, max-height 0.3s ease-out, opacity 0.3s ease;
            backdrop-filter: blur(10px);

        }

        &.open .dropdown-content {
            max-height: 200px;
            opacity: 1;
            transform: translateY(0);
            overflow-y: hidden;

            .dropdown-list {
                padding: 12px;

                .slide-left {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    opacity: 0;
                    transform: translateX(-20px);
                    animation: slideInLeft 1s forwards;
                    transition-delay: 0.1s;

                    p {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 12px;

                        &:hover {
                            border-radius: 8px;
                            background-color: #f8f9fa;
                        }
                    }
                }


                input[type="checkbox"] {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    width: 26.5px;
                    height: 25px;
                    border-radius: 6px; // Rounded corners
                    border: 3px solid transparent;
                    background-image: linear-gradient(white, white), linear-gradient(to bottom, #00c9b7, #81e07a); // Gradient border
                    background-origin: border-box;
                    background-clip: content-box, border-box;
                    cursor: pointer;
                    transition: 0.3s ease-in-out, border-color 0.3s ease-in-out;

                    &:checked {
                        background-color: #00c9b7; // Fill color when checked
                        background-image: none; // Removes the gradient when checked
                        position: relative;
                        border-color: transparent;

                        &:before {
                            content: "✔";
                            color: white;
                            font-size: 14px;
                            display: block;
                            text-align: center;
                            line-height: 23.5px;
                        }
                    }
                }
            }

        }
    }

}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}