// Import Nunito font
@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-Italic-VariableFont_wght.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-Black.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-BlackItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-BoldItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-ExtraBold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-ExtraBoldItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-ExtraLight.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-ExtraLightItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-Italic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-LightItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-MediumItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-SemiBold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/static/Nunito-SemiBoldItalic.ttf') format('truetype');
  font-style: italic;
}
