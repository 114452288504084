.reject-pricing-reason-wrap {
  padding: 30px 50px;

  .reject-pricing-reason-form {
    width: 350px;
  }

  .reject-reason-button-wrap {
    margin-top: 20px;

    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .button-wrap {
      width: max-content;
      height: 38px;

      button {
        padding: 0 10px !important;
        width: max-content;
      }

      .error-button {
        width: 150px;
      }
    }
  }
}
