.check-box-button-wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-label {
    font-size: 16px;
    font-weight: 500;
    color: var(--grey-1);

    span{
      color: var(--error);
    }
  }
  .custom-check-box {
    .check-box-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    
      .check-box-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 10px;
      }
    }
  }
  p{
    font-size: 14px;
  }
}