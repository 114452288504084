.table-field-allow-warp {
  position: relative;
}
.table-field-allow-container {
    display: display;
    max-height: 300px;
    box-sizing: border-box;
    position: absolute;
    padding: 20px 8px;
    border: 1px solid var(--border-grey);
    background-color: var(--grey-select-bg);
    border-radius: 8px;
    backdrop-filter: blur(3px);
    --Webkit-backdrop-filter: blur(3px);
    
    // display: flex;
    flex-direction: column;
    z-index: 9;

    .table-field-allow-header {
        .allow-all-field-button {
            padding: 0px 0px 10px 0px;
            .field-item {
                color: var(--primary-color);
                cursor: pointer;
            }
        }
    }

    .allow-field-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .field-item {
            display: flex;
            gap: 5px;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: var(--grey-bg);
            }
        }
    }
}