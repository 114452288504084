.charts-container {
  display: flex;
  // flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  width: 100%;
  // max-width: 1245px;
  box-sizing: border-box;
  align-items: center;

  .chart-card {
    flex: 1 1 40%;
    /* Make cards responsive to container width */
    background-color:var(--row-bg);
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    box-sizing: border-box;
    overflow: hidden;

    .content-section {
      min-width: 200px;
      margin-right: 24px;
      flex: 1;

      .title {
        font-size: 19px;
        font-weight: 400;
        margin: 0 0 2px 0;
      }
      
      .subtitle {
        color: var(--text-color-1);
        margin: 0 0 2px 0;
        font-size: 14px;
      }
      
      .percentage-row {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 35px;

        .percentage {
          border-radius: 35px;
          padding: 5px 6px;
        }
        
        .more-text {
          color: var(--grey-8);
          font-size: 14px;
          font-weight: 100;
        }
      }

      .submenu-container {
        padding: 4px;
      }
    }

    .chart-section {
      flex: 1;
      height: 140px;
    }
  }

  .dotted-line {
    display: flex;
    align-items: center;
    font-size: 15px;
    position: relative;
    &::before {
      content: "<- - - - -";
      flex: 1;
      display: block;
      color: var(--grey-2);
    }
    
    &::after {
      content: " - - - - ->";
      flex: 1;
      margin-left: 3px;
      display: block;
      color: var(--grey-2);
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 0 5px;
    }
  }

}

@media (min-width: 1120px) and (max-width: 1380px) {
  .container {
    .body-container {
      .charts-container {
        .dotted-line {
          &::before {
            content: "<- - -";
            flex: 1;
            display: block;
            color: var(--grey-2);
          }
          
          &::after {
            content: "- - ->";
            flex: 1;
            margin-left: 3px;
            display: block;
            color: var(--grey-2);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .container .body-container .charts-container {
    flex-direction: column;

    .chart-card {
      width: 100%;
      gap: 20px;

      .content-section {
        margin-right: 5px;
      }
    }
  }
}