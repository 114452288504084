.auto-complete-select-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  .input-label {
    font-size: 14px;
    font-weight: 600;
    color: var(--text-color);
    padding: 0px 0px 1px 2px;

    span {
      margin: 0 0 0 3px;
      color: var(--error);
      font-size: 16px;
    }

    &.error {
      color: var(--error);
    }
  }

  .auto-complete-select-container {
    position: relative;
    max-width: 100%;
    height: 48px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid var(--border-grey);
    background-color: var(--grey-select-bg);

    display: grid;
    grid-template-columns: max-content 1fr 20px;
    align-items: center;
    padding: 0px 14px;
    gap: 10px;

    flex-wrap: wrap;
    cursor: pointer;

    .auto-complete-input-field {
      width: 100%;
      height: 100%;

      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        color: var(--secondary-color);
        font-size: 14px;
        font-weight: 500;

        &::placeholder {
          color: var(--placeholder);
          font-weight: 300;
        }
      }
    }

    &.error {
      border: 1px solid var(--error);
    }

    &.open {
      border-radius: 12px 12px 0 0;
      background-color: #ebebeb80;
    }

    .custom-icon {
      display: flex;
    }
  }

  .custom-input-error-message {
    font-size: 12px;
    font-weight: 400;
    color: var(--error);
  }

  .dropdown-items-list {
    display: none;
    max-height: 300px;
    overflow: scroll;

    // box-sizing: border-box;
    position: absolute;
    z-index: 1300;
    padding: 10px 5px;
    border: 1px solid var(--border-grey);
    border-top: 1px solid var(--grey-1);
    background: #ebebeb80;
    border-radius: 0px 0px 8px 8px;
    backdrop-filter: blur(12px);
    --Webkit-backdrop-filter: blur(12px);

    flex-direction: column;

    &.open {
      display: flex;
    }

    .items {
      color: var(--grey-2);
      padding: 6px 14px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        color: var(--black-color);
        background-color: var(--input-bg);
      }
    }
  }

  p {
    font-size: 14px;
  }
}
