.report-warp {
  width: 100%;
  height: calc(100vh - 100px);
  overflow-y: scroll;

  .custom-table-wrap {
    .custom-table {
      .table-container {
        .table-body {
          .table-row {
            .table-row-data {
              .tag-default-label {
                max-width: 80%;
              }
            }
          }
        }
      }
    }
  }
}
