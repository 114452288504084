.custom-otp-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-label {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color);
    padding: 0px 0px 1px 2px;
  }

  .custom-otp-container {
    width: 100%;
    height: 48px; // Customize if need

    display: flex;
    gap: 5px;

    .otp-digit-container {
      flex: 1;
      text-align: center;

      width: 100%;
      height: 100%;
      outline: none;
      border-radius: 12px;
      color: var(--text-color);
      border: 1px solid var(--grey-2);
      background-color: rgba(var(--grey-4), 0.5);

      font-size: 14px;
      font-weight: 300;

      &.error {
        border: 1px solid var(--error);
      }

      // Hide the arrows (spinners) for number input fields
      &[type='number']::-webkit-outer-spin-button,
      &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield; // Hide arrows in Firefox
      }
    }
  }
}