.custom-input-container {
    .images-list {
        flex: 1;

        display: flex;
        .image-item {
            width: 30px;
            height: 30px;
            overflow: hidden;
            border-radius: 8px;
            border: var(--white-color) solid 1px;

            img {
                width: 100%;
                height: 100%;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                margin-left: -14px;
            }
        }
        .count {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -14px;
            background: var(--grey-3);
        }
    }
}
.full-page-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;

    &.open {
        display: flex;
    }

    .image-modal-box {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 26vw;
        padding: 20px;
        border-radius: 12px;
        animation: popupAnimate .5s ease-in-out;
        background: var(--grey-3);

        .modal-header {
            display: flex;
            position: relative;
            .modal-title {
                flex: 1;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
            }
            .close-icon {
                right: 0;
                top: 0;
                position: absolute;
            }
        }
        .modal-body {
            padding: 20px 0px;
            .image-grid {
                display: grid;
                grid-template-columns: auto auto auto;
                overflow-y: scroll;
                max-height: 300px;
                .image-item {
                    min-width: 50px;
                    border: var(--grey-4) solid 3px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    &.item-1 {
                        grid-row: 2 span;
                    }
                }
            }
        }
    }
}


@keyframes popupAnimate {
    from {
        right: -600px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}