.pagination-wrap {
  display: flex;
  align-items: center;
  gap: 25px;

  .limit-container {
    display: flex;
    align-items: center;
    gap: 15px;

    span {
      font-size: 15px;
      font-weight: 500;
      color: var(--table-header);
    }

    .limit-select-box {
      width: 66px;
      .custom-select-wrap {
        .custom-select-container {
          background: var(--white-color);
        }
        .dropdown-items-list {
          background: var(--white-color);
          padding: 3px;
          .items {
            padding: 2px 5px;
          }
        }
      }
    }

    .custom-select-wrap {
      .custom-select-container {
        height: 33px;
        border-radius: 8px;

        select {
          padding: 0 13px 0 3px;
          font-size: 16px;
        }

        .custom-icon {
          right: -4px;
        }
      }
    }
  }

  .pagination-container {
    display: flex;
    align-items: center;
    gap: 15px;
    list-style: none;

    .pagination-arrow {
      width: 33px;
      height: 33px;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      color: var(--table-data);
      border: 1px solid var(--border-grey-1);
      background-color: var(--border-grey-1);
    }

    .active-arrow {
      color: var(--white-color);
      background-color: var(--table-data);
    }

    .disable-arrow {
      cursor: not-allowed;
    }

    .pagination-item {
      width: 33px;
      height: 33px;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 16px;
      font-weight: 500;
      background-color: transparent;

      cursor: pointer;
      color: var(--table-data);
      border: 1px solid var(--border-grey-1);
    }

    .active-item {
      color: var(--white-color);
      background-color: var(--table-data);
    }

    .pagination-dots {
      padding: 0 0 5px;
      width: max-content;
      height: 46px;

      font-size: 18px;
      font-weight: 500;

      display: flex;
      align-items: flex-end;
    }
  }
}

@media screen and (max-width: 1537px) {
  .pagination-wrap {
    .limit-container {
      span {
        font-size: 12px;
      }
      .limit-select-box {
        .custom-select-wrap {
          .custom-select-container {
            height: 27px;
            .view-value {
              font-size: 12px;
            }
          }
        }
      }
    }
    .pagination-container {
      .pagination-arrow {
        width: 27px;
        height: 27px;
      }
      .pagination-item  {
        width: 27px;
        height: 27px;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .pagination {
    flex-direction: column;
  }
}