.revenue-management-toggle-wrap {
  margin: 0 30px 0 0;

  display: flex;
  align-items: center;
  gap: 30px;

  .custom-radio-button-wrap {
    gap: 7px;

    .radio-button-status {
      width: 18px;
      height: 18px;

      .radio-button-selected {
        width: 10px;
        height: 10px;
      }
    }

    .radio-button-label {
      font-size: 14px;
    }
  }
}
