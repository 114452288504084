.dashboard-wrap {
  width: 100%;
  height: calc(100vh - 100px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .cards-wrap {
      width: 100%;
      height: max-content;

      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      transition: all 0.4s ease-in-out;

      &.hide-cards {
        bottom: -400px;
      }

      .each-card-container {
        position: relative;
        flex: 1 0 327px;
        background: linear-gradient(
          180deg,
          rgba(0, 167, 157, 0.22) 0%,
          rgba(110, 194, 130, 0.22) 100%
        );
        border: 1px solid var(--grey-4);
        border-radius: 15px;

        padding: 15px 20px;

        display: flex;
        flex-direction: column;
        gap: 17px;

        h3 {
          font-size: 16px;
          font-weight: 600;
          color: var(--text-color);
        }

        h1 {
          font-size: 30px;
          font-weight: 700;
          color: var(--primary-color);

          span {
            font-weight: 400;
            font-size: 17px;
            color: var(--grey-1);
          }
        }

        .device-count-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          .footer-card {
            display: flex;
            gap: 10px;
            align-items: center;
            color: var(--grey-1);

            .tag-success-label {
              padding: 5px 10px;
              border-radius: 35px;
            }
            .tag-danger-label {
              padding: 5px 10px;
              border-radius: 35px;
            }
            p {
              font-size: 14px;
              font-weight: 700;
              color: var(--text-color);
  
              span {
                margin: 0 0 0 5px;
              }
            }

          }
        }

        .card-arrow-wrap {
          width: 80px;
          height: 80px;
          top: -1px;
          right: -1px;
          position: absolute;

          background-color: var(--white-color);
          border-bottom-left-radius: 15px;
          
          display: flex;
          justify-content: end;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -15px;
            width: 15px;
            height: 15px;
            background-color: transparent;
            border-top-right-radius: 15px;
            box-shadow: 3px -3px 0 2px var(--white-color);
          }
          &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -15px;
            width: 15px;
            height: 15px;
            background-color: transparent;
            border-top-right-radius: 15px;
            box-shadow: 3px -3px 0 2px var(--white-color);
          }

          .card-arrow-container {
            width: 85%;
            height: 85%;
            position: relative;

            background: linear-gradient(
              263.29deg,
              rgba(46, 158, 58, 0.15) -29.21%,
              rgba(0, 153, 214, 0.15) 104.28%
            );
            border-radius: 15px;

            overflow: hidden;

            span {
              position: absolute;
              transform: translate(-50%, -50%);

              transition:
                top 0.4s ease-in-out,
                bottom 0.4s ease-in-out,
                opacity 0.6s ease-in-out;
            }

            .active-icon {
              left: 50%;
              bottom: -150%;
              cursor: pointer;
              pointer-events: none;
            }

            .in-active-icon {
              top: 54%;
              left: 50%;
              pointer-events: all;
            }

            &.hovered {
              .active-icon {
                bottom: -55%;
                pointer-events: all;
              }

              .in-active-icon {
                top: -50%;
                pointer-events: none;
              }
            }
          }
        }
      }
    }
    .reports-graph-content {
      border-radius: 16px;
      background-color: white;
      
      .reports-graph-header-container {
          .reports-title {
              font-size: 16px;
              font-weight: 500;
              padding: 20px 20px 0 20px;
          }
          
          .reports-graph-header {
              justify-content: space-between;
              display: flex;
              flex-wrap: wrap;
              padding: 20px;
              align-items: center;
              
              .reports-toggle-btn {
                  .toggle-button {
                      padding: 0px;
                      border: none;
                      background-color: #FFFFFF;
                      
                      
                      .btn {
                          padding: 14px 28px;
                          margin-right: 0px;
                          border: 0.5px solid #E9ECEF;
                          border-radius: 0px;
                          
                          &.active {
                              background-color: #212529;
                              color: white;
                              border: 0.1px solid #212529;
                              
                          }
                          
                          &:first-child {
                              border-radius: 10px 0 0 10px;
                              margin-right: 0;
                          }
                          
                          &:last-child {
                              margin-right: 0;
                              border-radius: 0px 10px 10px 0px;
                              
                          }
                          
                      }
                  }
              }

              .reports-export-btn {
                display: flex;
                gap: 20px;
                .chart-label-wrap {
                  display: flex;
                  gap: 20px;
          
                  .each-chart-label {
                    display: flex;
                    align-items: center;
                    gap: 10px;
          
                    .each-label-color {
                      width: 11px;
                      height: 11px;
                      border-radius: 50%;
                      background-color: #F79009;
                    }
          
                    p {
                      font-size: 16px;
                      font-weight: 400;
                      color: var(--grey-1);
                    }
          
                    &:last-child {
                      .each-label-color {
                        background-color: #0399e0;
                      }
                    }
                  }
                }
              }
          }
      }
      
      .reports-graph {
          padding: 20px;
          width: calc(100vw - 160px);
      }
  }
  }
}
