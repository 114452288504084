.custom-input-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-label {
    font-size: 16px;
    font-weight: 500;
    color: var(--grey-1);
  }
  .custom-input-container {
    max-width: 100%;
    height: 48px; // Customize if need
    overflow: hidden;

    display: flex;
    gap: 10px;

    padding: 0 14px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--border-grey);
    background-color: rgba(var(--grey-bg), 0.5);

    .frond-text {
      display: flex;
      gap: 3px;
      align-items: center;
      padding-right: 5px;
      border-right: solid 1px var(--grey-1);
      margin-right: 5px;
    }

    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background-color: transparent;

      font-size: 14px;
      font-weight: 300;

      &::placeholder {
        color: var(--grey-2);
      }

      // Hide the arrows (spinners) for number input fields
      &[type='number']::-webkit-outer-spin-button,
      &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield; // Hide arrows in Firefox
      }
    }

    &.error {
      border: 1px solid var(--error);
    }

    span {
      cursor: pointer;
      display: flex;
    }
  }
  p {
    font-size: 14px;
  }
}

.country-code-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  z-index: 1300;
  top: 30px;
  // background: var(--white-color);
  background-color: var(--grey-select-bg);
  backdrop-filter: blur(12px);
  border-radius: 8px;

  .list-item {
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: var(--grey-3);
    }
  }
}
