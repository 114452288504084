.battery-loader-wrap {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;

  background-color: var(--text-color);

  display: flex;
  align-items: center;
  justify-content: center;

  .battery-loader-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    width: 100%;
    height: 100%;
    border-radius: 50%;

    background-color: var(--primary-color);
    animation: animateBG 1.5s ease-in-out infinite;
  }

  .battery-wrap {
    position: relative;
    z-index: 2;

    .battery-icon {
      animation: batteryRotate 2s linear;
    }

    .electricity-bolt-black {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    .electricity-bolt-green {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;

      .icon-to-fill {
        fill: black;
        animation: fillGreen 2s ease-in-out forwards;
        mask: linear-gradient(transparent 50%, black 50%);
        mask-size: 100% 200%;
        mask-position: 0 100%;
      }
    }
  }
}

@keyframes animateBG {
  0% {
    width: 100%;
    height: 100%;
  }

  50% {
    width: 1%;
    height: 1%;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes batteryRotate {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes fillGreen {
  0% {
    fill: black;
    mask-position: 0 0;
  }
  100% {
    fill: var(--primary-color);
    mask-position: 0 100%;
  }
}
