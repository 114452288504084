.tag {
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &.tag-danger {
        background-color: var(--error);
        color: var(--white-color);
    }
}

.text-danger {
    color: var(--error);
}
.text-primary {
    color: var(--primary-color);
}
.text-success {
    color: var(--success);
}

.flex-row {
    display: flex;
    gap: 10px;
    align-items: center;
}

.table-bottom-button-list {
    display: flex;
    align-items: center;
    gap: 30px;
}
.table-top-button-list {
    display: flex;
    gap: 10px;
    .table-button-item {
        .button-wrap {
            .back-button {
                padding: 0 6px;
                border: 1px solid var(--grey-4);
            }
        }
    }
}

.tag-success-label {
    display: flex;
    gap: 6px;
    padding: 5px 12px;
    border-radius: 8px;
    align-items: center;
    background-color: var(--success-bg);
    color: var(--success);
    span {
        letter-spacing: 0.9px;
    }
}
.tag-default-label {
    display: flex;
    gap: 6px;
    padding: 5px 12px;
    border-radius: 8px;
    align-items: center;
    background-color: var(--success-bg);
    color: var(--grey-1);

    span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 0.9px;
    }
}
.tag-danger-label {
    display: flex;
    gap: 6px;
    padding: 5px 12px;
    border-radius: 8px;
    align-items: center;
    background-color: var(--error-bg);
    color: var(--error);
    span {
        letter-spacing: 0.9px;
    }
}
.tag-warning-label {
    display: flex;
    gap: 6px;
    padding: 5px 12px;
    border-radius: 8px;
    align-items: center;
    background-color: var(--warning-bg);
    color: var(--warning);
    span {
        letter-spacing: 0.9px;
    }
}
