.dashboard-map-wrap {
  width: 100%;
  height: max-content;
  h1 {
    color: var(--text-color-1);
    font-size: 16px;
    font-weight: 600;
  }

  .map-search-wrap {
    width: 400px;
  }
  .dashboard-map-container {
    position: relative;
    margin: 10px 0 0;
    width: 100%;
    height: 395px;

    border-radius: 15px;
    overflow: hidden;


    .absolute-background {
      pointer-events: none;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 400;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(250, 250, 250, 0.5) 100%);
    }

    // override the existing
    .leaflet-popup-content-wrapper {
      background-color: rgb(from var(--white-color) r g b / 0.5);
      backdrop-filter: blur(5px);
    }

    // Hide tooltip arrow
    .leaflet-popup-tip {
      display: none;
    }

    // Popup details SCSS
    .map-station-details-wrap {
      &.alert-wrap {
        .map-station-details-container {
          display: flex;
          align-items: center;
          gap: 10px;
          span {
          }

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 700;
            color: var(--text-color);
          }
        }
      }
    }

    .leaflet-popup-content {
      width: max-content !important;
    }
  }
}

.map-station-active-container {
  width: 550px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1.3fr max-content 1fr;
  gap: 15px;

  p {
    margin: 0;
  }

  .map-station-details {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .details-header {
      display: flex;
      align-items: center;
      gap: 15px;

      .details-header-image-wrap {
        // position: relative;
        width: 70px;
        height: 70px;
        border-radius: 10px;

        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .details-header-image {
          width: 80px;
          height: auto;
          object-fit: contain;
          z-index: 9999;

          position: absolute;
          top: 40px;
          left: 18px;
        }
      }

      .name-and-status {
        h2 {
          font-size: 16px;
          font-weight: 600;
          color: var(--text-co lor-1);
        }

        .status-wrap {
          margin-top: 5px;
          padding: 3px 8px;
          width: max-content;

          display: flex;
          align-items: center;
          gap: 5px;

          background-color: rgb(from var(--success) r g b / 0.1);
          border-radius: 12px;

          .status-point {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: var(--success);
          }
          p {
            font-size: 14px;
            font-weight: 600;
            color: var(--success);
          }
        }
      }
    }

    .address-details {
      .place-with-icon {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          display: flex;

          font-size: 14px;
          font-weight: 600;
          color: var(--text-color);
        }
      }

      p {
        margin: 4px 0 0;
        color: var(--grey-1);
        font-size: 12px;
        font-weight: 500;
      }
    }

    .user-details {
      display: flex;
      align-items: center;
      gap: 7px;

      span {
        display: flex;
      }

      .count {
        color: var(--text-color-1);
        font-size: 16px;
        font-weight: 600;
      }

      .message {
        font-size: 12px;
        font-weight: 500;
        color: var(--success);
      }
    }
  }

  .map-station-center-border {
    height: 100%;
    width: 1.5px;
    background: linear-gradient(#f8f9fa 0%, #a0a7af 80%, #f8f9fa 100%);
  }

  .map-station-availability-wrap {
    .availability-status-wrap {
      display: flex;
      flex-direction: column;
      gap: 25px;

      .each-availability {
        display: flex;
        align-items: center;
        gap: 7px;
        span {
          display: flex;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          color: var(--text-color-1);
        }
      }
    }

    .view-more-button {
      margin: 20px 0 0;
      width: max-content;

      .submit-button {
        flex-direction: row-reverse;
      }
    }
  }
}