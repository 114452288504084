:root {
    --card-left-position: 5%;
    --card-bottom-position: -16px;
}
.active-log-card-warp {
    position: absolute;
    top: calc(100vh - 700px);
    width: calc(100vw - 205px);
    display: none;
    padding: 3px;
    border-radius: 20px;
    z-index: 9;
    height: 295px;
    box-sizing: border-box;

    &::after {
        content: "";
        width: 40px;
        height: 40px;
        position: absolute;
        rotate: 45deg;
        bottom:  var(--card-bottom-position);
        left: var(--card-left-position);
        border-radius: 10px;
        background: var(--white-color);
        border: var(--success) 3px solid;

    }

    background: var(--card-border-gradient-color);

    &.open {
        display: block;
    }
    .active-log-card-body {
        position: relative;
        border-radius: 17px;
        z-index: 10;
        background: var(--white-color);
        height: 289px;

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }

        .active-log-content {
            padding: 20px;
            .active-logs-list {
                display: flex;
                gap: 30px;
                overflow: scroll;
                max-height: 250px;

                .active-loge-item {
                    flex: 0 0 250px;
                    display: flex;
                    gap: 20px;
                    flex-direction: column;
                    position: relative;
                    .icon {
                        margin: 0 0 0 14px;
                        &.line::after {
                            content: '';
                            width: 110%;
                            height: 1px;
                            top: 10px;
                            position: absolute;
                            background-color: var(--black-color);
                        }
                    }
                    .details {
                        display: flex;
                        gap: 10px;
                        align-items: flex-start;

                        .calender-icon {
                            background-color: var(--border-grey-1);
                            padding: 5px;
                            display: flex;
                            border-radius: 8px;
                        }
                        .calender-details {
                            display: flex;
                            gap: 20px;
                            flex-direction: column;
                            .date {
                                font-size: 16px;
                                font-weight: 600;
                                padding-top: 5px;
                            }

                            .time-list {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;

                                .time-item {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 5px;

                                    .title {
                                        color: var(--table-header);
                                        font-size: 14px;
                                    }

                                    .sub-title {
                                        display: flex;
                                        gap: 5px;
                                        font-size: 16px;
                                        align-items: center;
                                        font-weight: 600;
                                        .text-success {
                                            display: flex;
                                            align-items: center;
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
                .not-found {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 210px;
                    .title {
                        font-size: 19px;
                        font-weight: 600;
                        letter-spacing: 0.6px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1570px) {
    .active-log-card-warp {
        --card-left-position: 25%;
    }
}
@media screen and (max-width: 1255px) {
    .active-log-card-warp {
        --card-left-position: 35%;
    }
}
@media screen and (max-width: 890px) {
    .active-log-card-warp {
        --card-left-position: 50%;
    }
}