.custom-file-input-wrap {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  .input-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);

    span {
      color: var(--error);
    }
  }
  .custom-file-input-container {
    min-height: 48px; // Customize if need
    overflow: hidden;

    padding: 0 14px;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    border-radius: 12px;
    // border: 1px solid var(--border-grey);
    // background-color: rgb(from var(--grey-bg) r g b / 0.5);

    border: 1px solid var(--border-grey);
    background: var(--grey-bg);

    .selected-file-wrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .count-tag {
        padding: 2px 3px;
        background: var(--grey-2);
        border-radius: 8px;
      }

      .single-selected-file {
        width: 99px;
        height: 18px;
        align-items: center;

        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: center;
        gap: 8px;

        .doc-icon {
          pointer-events: none;
        }

        p {
          font-size: 14px;
          font-weight: 300;

          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          user-select: none; /* Standard syntax */
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
        }

        .remove-icon {
          color: var(--error);
          cursor: pointer;
        }
      }

      .placeholder {
        font-size: 14px;
        font-weight: 300;
        color: var(--grey-2);
      }
    }

    .file-input-icon-wrap {
      display: flex;

      .file-input-icon {
        display: flex;
        cursor: pointer;

        &.disabled {
          color: var(--grey-3);
          cursor: not-allowed;
        }
      }
    }

    &.error {
      border: 1px solid var(--error);
    }
  }
  p {
    font-size: 14px;
  }
}

.dropdown-items-list {
  display: none;
  max-height: 300px;
  overflow-y: scroll;
  box-sizing: border-box;
  position: absolute;
  padding: 20px 8px;
  border: 1px solid var(--border-grey);
  border-top: 1px solid var(--grey-1);
  background-color: var(--grey-select-bg);
  border-radius: 0px 0px 8px 8px;
  backdrop-filter: blur(12px);
  --Webkit-backdrop-filter: blur(12px);

  // display: flex;
  flex-direction: column;
  z-index: 1400;

  &.open {
    display: flex;
  }

  .items {
    cursor: pointer;
    padding: 6px 3px;
    color: var(--grey-2);
    padding: 10px;
    border-radius: 8px;

    &:hover {
      color: var(--black-color);
      background-color: var(--pure-white-color);
    }
  }
}
