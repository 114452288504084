.top-header-warp {
    padding: 10px 10px 10px 0px;
    .top-header-buttons {
        display: flex;
        justify-content: space-between;
        .top-header-left {
            display: flex;
            gap: 20px;
            .button-group {
                display: flex;
                gap: 10px;
                .button-item {
                    .button-wrap {
                        button {
                            border: solid 1px var(--grey-3);
                        }
                    }
                }
            }
        }
        .top-header-right {
            display: flex;
            gap: 10px;
                    .button-wrap {
                        button {
                            border: solid 1px var(--grey-3);
                        }
                    }
        }
    }
}