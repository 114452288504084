.toggle-button {
  display: flex;
  border-radius: 12px;
  // background-color: #F9F9F999;
  padding: 5px;
  border: 1px solid var(--grey-4);
  width: fit-content;

  .btn {
    padding: 7px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    flex-grow: 1;

    font-size: 14px;
    color: #333;

    &.active {
      background-color: var(--primary-color); // Green background for active button
      color: white;
    }

    &:last-child {
      margin-right: 0;
    }

    .icon {
      margin-right: 8px;
      display: flex;
      align-items: center;

      &.active-icon {
        fill: var(--white-color);
        transform: scale(1.1);
      }
    }
  }
}
